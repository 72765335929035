<template>
  <div class="EquipmentAlarmManagement">
    <div class="gaugeoutfit2">
			<span class="xian"></span>
			<span class="title">{{groupTitle}}</span>
		</div>
    <div class="EquipmentAlarm_cont">
      <div class="DevOps_gz_yj">
        <div class="DevOps_top_l DevOps_top_bjsbs"
            v-loading="alarmloading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(1,34,48, 0)">
          <div class="DevOps_top_right">
            <div class="DevOps_top_item4">
              <div class="DevOps_item_text">报警设备数</div>
              <div class="DevOps_item_numl DevOps_item_heigh Cont_num">{{alarmEuipmentCount}}</div>
            </div>
          </div>
        </div>
        <div class="DevOps_top_r DevOps_top_bjzs">
          <div class="DevOps_top_right" 
            v-loading="alarmloading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(1,34,48, 0)">
            <div class="DevOps_top_item1">
              <div class="DevOps_item_text">报警总数</div>
              <div class="DevOps_item_numr DevOps_item_heigh Cont_num">{{alarmCount}}</div>
            </div>
            <div class="DevOps_top_item2">
              <div class="DevOps_item_text">高</div>
              <div class="DevOps_item_numr DevOps_item_heigh Cont_num">{{0}}</div>
            </div>
            <div class="DevOps_top_item2">
              <div class="DevOps_item_text">中</div>
              <div class="DevOps_item_numr DevOps_item_mid Cont_num">{{0}}</div>
            </div>
            <div class="DevOps_top_item3">
              <div class="DevOps_item_text">低</div>
              <div class="DevOps_item_numr DevOps_item_low Cont_num">{{0}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Equipment_main">
        <div class="Equipment_txt">设备报警列表</div>
        <div class="Equipment_ser">
          <div class="condition Equipment_box"
            v-loading="Sbmcloading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(1,34,48, 0)"
            >
            <span class="SelectText">设备名称：</span>
            <el-select v-model="Sbmcvalue" @change="selectedNameLocal" :style="{width:width}"
                      placeholder="请选择" size="small">
              <el-option
                v-for="(item, index) in equipNameList"
                :key="index"
                :label="item.EquipmentName"
                :value="item.EquipmentId">
              </el-option>
            </el-select>
          </div>
          <div class="condition Equipment_box"
            v-loading="Bjjbloading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(1,34,48, 0)"
            >
            <span class="SelectText">报警级别：</span>
            <el-select v-model="Bjjbvalue" @change="selectedLevelLocal" :style="{width:width}"
                      placeholder="请选择" size="small">
              <el-option
                v-for="(item, index) in equipLevelList"
                :key="index"
                :label="item.EquipmentTypeName"
                :value="item.EquipmentTypeId">
              </el-option>
            </el-select>
          </div>
          <div class="condition Equipment_box"
            v-loading="Bjztloading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(1,34,48, 0)"
            >
            <span class="SelectText">报警状态：</span>
            <el-select v-model="Bjztvalue" @change="selectedStatuLocal" :style="{width:width}"
                      placeholder="请选择" size="small">
              <el-option
                v-for="(item, index) in equipStatuList"
                :key="index"
                :label="item.EquipmentTypeName"
                :value="item.EquipmentTypeId">
              </el-option>
            </el-select>
          </div>
          <!--开始时间-->
          <div class="time_row selectTime">
            <day @selectedDate="selecteBeginTime" :day="BeginTime" :btn="false" label="开始时间"></day>
          </div>
          <!--结束时间-->
          <div class="time_row selectTime">
            <day @selectedDate="selectedEndTime" :day="EndTime" :btn="false" label="结束时间"></day>
          </div>
          <el-button class="btn" size="small" type="success" @click="Save()">确认</el-button>
        </div>
        <div class="DevOps_table_box">
          <el-table
            class="DevOps_box_tab"
            :data="sbyjList"
            v-loading="loading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(1,34,48, 0)"
            :header-cell-style="{ 'background':BackgroundColors}"
          >
            <el-table-column type="index" label="编号" width="100"> </el-table-column>
            <el-table-column prop="EquipmentName" label="设备名称">
            </el-table-column>
            <el-table-column prop="AlarmType" label="报警类型">
            </el-table-column>
            <el-table-column prop="" label="报警级别">
            </el-table-column>
            <el-table-column prop="AlarmDescription" label="报警内容">
            </el-table-column>
            <el-table-column prop="CreateTime" label="报警日期">
            </el-table-column>
            <el-table-column prop="" label="报警状态">
            </el-table-column>
            <el-table-column prop="" label="处理人">
            </el-table-column>
            <el-table-column prop="" label="处理时间">
            </el-table-column>
          </el-table>

          <el-pagination
            class="DevOps_box_page"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pagesize"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import day from '@/components/date/day'
import { formatDate } from '@/common/js/date.js'
import { GetAlarmInfos, GetEuipments, GetEuipmentAlarmInfos } from "@/api/EquipmentOperationMonitoring"; //页面接口
export default {
  components:{
    day,

  },
  data() {
    return {
      loading:false,
      emptyMsg:false,
      errorMsg:false,
      noAuthMsg:false,
      currentPage:1,
      pagesize:10,
      totalCount:0,

      alarmEuipmentCount:0,
      alarmCount:0,
      alarmloading:false,
      groupTitle: this.$route.query.title,
      // BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',
      BackgroundColors:'#2a343b',
      sbyjList: [
        // {
        //   AlarmCode:'xxxxxxxxxx',
        //   AlarmDescription:'xxxxxxxxxx',
        //   CreateTimeStr:'xxxxxxxxxx',
        // },
      ],

      BeginTime: formatDate(new Date(), 'yyyy-MM-dd'),
      EndTime: formatDate(new Date(), 'yyyy-MM-dd'),
      width:'10vw',
      Sbmcvalue: 0,
      Bjjbvalue: 0,
      Bjztvalue: 0,
      equipNameList:[
        {
          EquipmentName:'全部',
          EquipmentId:0
        },
      ],
      equipLevelList:[
        {
          EquipmentTypeName:'全部',
          EquipmentTypeId:0
        },
      ],
      equipStatuList:[
        {
          EquipmentTypeName:'全部',
          EquipmentTypeId:0
        },
      ],
      Sbmcloading:false,
      Bjjbloading:false,
      Bjztloading:false,

    }
  },
  mounted() {
    this.handleGetAlarmInfo()
    this.handleGetEquipList()
    this.handleGetTableList()
  },
  methods: {
    //开始时间-结束时间
    selecteBeginTime(val) {
      this.BeginTime = formatDate(new Date(val), 'yyyy-MM-dd');
    },
    selectedEndTime(val) {
      this.EndTime = formatDate(new Date(val), 'yyyy-MM-dd');
    },
    //确定
		Save(){
      if (new Date(this.BeginTime) > new Date(this.EndTime)) {
        this.$notify.info({
          title: '消息',
          message: '开始时间不能大于结束时间'
        });
        return;
      }
      this.handleGetTableList()
		},
    handleGetAlarmInfo(){
      this.alarmloading = true;
      this.alarmCount = 0;
      this.alarmEuipmentCount = 0;
      GetAlarmInfos({
        AgencyId:sessionStorage.getItem('agencyId'),//机构id
        EquipmentId:this.Sbmcvalue,
      }).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.alarmloading = false;
          this.alarmCount = 0;
          this.alarmEuipmentCount = 0;
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }
        this.alarmloading = false;

        this.alarmCount = obj.alarmCount;
        this.alarmEuipmentCount = obj.alarmEuipmentCount;
      }).catch(err => {
        this.alarmloading = false;
        this.alarmCount = 0;
        this.alarmEuipmentCount = 0;
      })
    },
    selectedNameLocal(e){

      // this.$nextTick(()=>{
      //   this.handleGetTableList()
      // })
    },
    handleGetEquipList(){
      this.Sbmcloading = true;
      this.equipNameList = [];
      GetEuipments({
        AgencyId:sessionStorage.getItem('agencyId'),//机构id
      }).then(res => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.Sbmcloading = false;
          this.equipNameList = [];
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }
        this.Sbmcloading = false;
        this.equipNameList = obj;
      }).catch(err => {
        this.Sbmcloading = false;
        this.equipNameList = [];
      })
    },
    selectedLevelLocal(e){
      
    },
    selectedStatuLocal(e){
      
    },
    handleGetTableList(){
      this.loading = true;
      this.sbyjList = [];
      GetEuipmentAlarmInfos({
        AgencyId:sessionStorage.getItem('agencyId'),//机构id
        strStartDate: this.BeginTime,
        strStopDate: this.EndTime,
        equipmentId: this.Sbmcvalue,
        curPage: this.currentPage,
        pageCount: this.pagesize,
      }).then(res => {
        let list = res.Content;
        if(res.Code==-1) {
          this.loading = false;
          this.sbyjList = [];
          this.$notify.info({
            title:'消息',
            message: '查询失败'
          });
          return;
        }
        this.loading = false;
        this.sbyjList = list.data ? list.data:[];
        this.totalCount = list.totalPage;
      }).catch(err => {
        this.loading = false;
        this.sbyjList = [];
      })
    },
    handleSizeChange(size){
      this.pagesize = size;
      this.handleGetTableList()
    },
    handleCurrentChange(page){
      this.currentPage = page;
      this.handleGetTableList()
    },
  },
}
</script>
<style lang="scss">
.Equipment_box .el-input__inner{
  background-color: #1f2930 !important;
  color: #fff;
}
.DevOps_box_tab .el-table__body-wrapper{
  height: calc(100% - 38px);
  overflow-y: auto;
}
.Equipment_ser .el-input__icon{
  line-height: 100%;
}
.DevOps_box_page .btn-next, 
.DevOps_box_page .btn-prev{
  background: center center no-repeat rgba(0, 0, 0, 0) !important;
  color: #fff;
}
.DevOps_box_page button:disabled{
  background-color: transparent
}
.DevOps_box_page .el-pager li.active {
  color: rgba(4, 152, 78, 1);
  cursor: default;
}
.DevOps_box_page .el-pager li{
  color: #fff;
  background: #1f2930 !important;
  margin: 0 2px;
}
.DevOps_box_page .el-pagination__total{
  color: #fff;
}
.DevOps_box_page .el-pagination__jump{
  color: #fff;
}
.DevOps_box_page{
  text-align: right;
}
.DevOps_box_page .el-pagination__editor.el-input .el-input__inner{
  color: #fff;
}
</style>
<style scoped lang="scss">
@import '@/assets/mixin.scss';
.EquipmentAlarmManagement{
  height: calc(100% - 90px);
}
.DevOps_top_bjsbs{
  background: url('../../../static/images/bjsb_icon.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.DevOps_top_bjzs{
  background: url('../../../static/images/bjzs_icon.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.condition{
  display: inline-block;
}
.Equipment_box{
  margin-right: 10px;
}
.btn{
  margin-left: 15px;
}
.DevOps_top_item1 {
  width: 50%;
}
.DevOps_top_item2 {
  width: 40%;
}
.DevOps_top_item3 {
  width: 25%;
}
.DevOps_top_item4 {
  width: 100%;
}
.DevOps_item_heigh{
  color: #d00032;
}
.DevOps_item_mid{
  color: #ff9600;
}
.DevOps_item_low{
  color: #00d392;
}
.Cont_num{
	font-family: 'GemunuLibre-Regular';
  font-size: 22px;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
  .EquipmentAlarm_cont{
    height: calc(100% - 60px);
    padding: 20px 20px 0;
    // @include background_color("background_color_white");
    // background: url('../../../static/main/EquipmentMonitoringBg.png');
    background: url('../../../static/images/bck_bord_big.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
  }
  .DevOps_gz_yj {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .DevOps_top_l{
    width: 22%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include background_color("background_color_white");
  }
  .DevOps_top_r {
    width: 77%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include background_color("background_color_white");
  }
  .DevOps_top_right {
    // width: calc(100% - 100px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 120px;
  }

  .Equipment_main{
    padding: 20px;
    border-radius: 4px;
    background-image: url('../../../static/main/BigBackground.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position:center;
    height: calc(100% - 195px);
  }
  .Equipment_txt{
    padding-bottom: 10px;
    border-bottom: 1px solid #15232e;
  }
  .Equipment_ser{
    display: flex;
    align-items: center;
    margin: 15px 0;
  }
  .DevOps_table_box{
    height: calc(100% - 135px);
  }
  .DevOps_box_tab{
    height: 100%;
  }
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
  .EquipmentAlarm_cont{
    height: calc(100% - 6vh);
    padding: 20px 20px 0;
    // @include background_color("background_color_white");
    // background: url('../../../static/main/EquipmentMonitoringBg.png');
    background: url('../../../static/images/bck_bord_big.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .DevOps_gz_yj {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .DevOps_top_l{
    width: 22%;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include background_color("background_color_white");
  }
  .DevOps_top_r {
    width: 77%;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include background_color("background_color_white");
  }
  .DevOps_top_right {
    // width: calc(100% - 100px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 120px;
  }

  .Equipment_main{
    padding: 20px;
    border-radius: 4px;
    background-image: url('../../../static/main/BigBackground.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position:center;
    height: calc(100% - 19.5vh);
  }
  .Equipment_txt{
    padding-bottom: 10px;
    border-bottom: 1px solid #15232e;
  }
  .Equipment_ser{
    display: flex;
    align-items: center;
    margin: 15px 0;
  }
  .DevOps_table_box{
    height: calc(100% - 13.5vh);
  }
  .DevOps_box_tab{
    height: 100%;
  }
}
</style>